<template>
  <default-layout>
    <div class="container help--container">
      <banner :banner-text="bannerText" />
      <div class="help--wrapper">
        <div class="left--col">
          <page-select></page-select>
        </div>
        <div class="right--col" v-if="selectedHelp">
          <div class="help--content">
            <div class="content--text">
              <rentfix-general v-if="selectedHelp.url === 'apa-itu-rentfix'" />
              <add-listing v-else-if="selectedHelp.url === 'listing-di-rentfix'" />
              <about-listing v-else-if="selectedHelp.url === 'seputar-listing'" />
              <request-to-book v-else-if="selectedHelp.url === 'request-to-book'" />
              <about-booking v-else-if="selectedHelp.url === 'about-booking'" />
              <digital-agreement v-else-if="selectedHelp.url === 'digital-agreement'" />
              <installment v-else-if="selectedHelp.url === 'installment'" />
              <health-protocol v-else-if="selectedHelp.url === 'health'" />

              <delete-account v-else-if="selectedHelp.url === 'tutup-akun-rentfix'" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <script v-html="jsonld" type="application/ld+json"></script>
  </default-layout>
</template>

<script>
import DefaultLayout from '@/layouts/default';
import Banner from '@/components/utils/page-banner';
import PageSelect from '@/components/utils/page-select';
const RentfixGeneral = () => import('@/components/static-page/help/rentfix-general');
const AddListing = () => import('@/components/static-page/help/add-listing');
const AboutListing = () => import('@/components/static-page/help/seputar-listing');
const RequestToBook = () => import('@/components/static-page/help/request-to-book');
const AboutBooking = () => import('@/components/static-page/help/about-booking');
const DigitalAgreement = () => import('@/components/static-page/help/digital-agreement');
const DeleteAccount = () => import('@/components/static-page/help/delete-account');
const Installment = () => import('@/components/static-page/help/installment');
const HealthProtocol = () => import('@/components/static-page/help/health-protocol');
import HelperMixin from '@/mixins/helpers';
import { mapState } from 'vuex';

export default {
  mixins: [HelperMixin],
  components: {
    DefaultLayout,
    Banner,
    PageSelect,
    RentfixGeneral,
    AddListing,
    AboutListing,
    RequestToBook,
    AboutBooking,
    DigitalAgreement,
    Installment,
    HealthProtocol,
    DeleteAccount,
  },
  computed: {
    ...mapState({
      selectedHelp: (state) => state.v2.help.selectedHelp,
      helpList: (state) => state.v2.help.helpList,
    }),
    jsonld() {
      return {
        '@context': 'https://schema.org',
        '@type': 'FAQPage',
        mainEntity: [
          {
            '@type': 'Question',
            name: this.$t('FAQ.rentfixgeneral'),
            acceptedAnswer: {
              '@type': 'Answer',
              text:
                this.$t('FAQ.rentfixgeneraldesc1') +
                ' ' +
                this.$t('FAQ.rentfixgeneraldesc2') +
                ' ' +
                this.$t('FAQ.rentfixgeneraldesc3'),
            },
          },
          {
            '@type': 'Question',
            name: this.stripHtml(this.$t('FAQ.addListingQ1')),
            acceptedAnswer: {
              '@type': 'Answer',
              text: this.$t('FAQ.addListingAns1'),
            },
          },
          {
            '@type': 'Question',
            name: this.stripHtml(this.$t('FAQ.desktop')),
            acceptedAnswer: {
              '@type': 'Answer',
              text: this.stripHtml(
                this.$t('howItWorks.general.step1') +
                  ' ' +
                  this.$t('howItWorks.general.step2') +
                  ' ' +
                  this.$t('howItWorks.general.step3') +
                  ' ' +
                  this.$t('howItWorks.general.step4') +
                  ' ' +
                  this.$t('howItWorks.general.step5') +
                  ' ' +
                  this.$t('howItWorks.general.step6'),
              ),
            },
          },
          {
            '@type': 'Question',
            name: this.stripHtml(this.$t('howItWorks.general.stepMobile')),
            acceptedAnswer: {
              '@type': 'Answer',
              text: this.stripHtml(
                this.$t('howItWorks.general.stepM1') +
                  ' ' +
                  this.$t('howItWorks.general.stepM2') +
                  ' ' +
                  this.$t('howItWorks.general.step3') +
                  ' ' +
                  this.$t('howItWorks.general.step4') +
                  ' ' +
                  this.$t('howItWorks.general.step5') +
                  ' ' +
                  this.$t('howItWorks.general.step6'),
              ),
            },
          },
          {
            '@type': 'Question',
            name: this.stripHtml(this.$t('FAQ.payQ')),
            acceptedAnswer: {
              '@type': 'Answer',
              text: this.$t('FAQ.payA'),
            },
          },
          {
            '@type': 'Question',
            name: this.stripHtml(this.$t('FAQ.durationQ')),
            acceptedAnswer: {
              '@type': 'Answer',
              text: this.$t('FAQ.durationA'),
            },
          },
          {
            '@type': 'Question',
            name: this.stripHtml(this.$t('FAQ.otherFeeQ')),
            acceptedAnswer: {
              '@type': 'Answer',
              text: this.$t('FAQ.otherFeeA'),
            },
          },
          {
            '@type': 'Question',
            name: this.stripHtml(this.$t('FAQ.requestBook.q1')),
            acceptedAnswer: {
              '@type': 'Answer',
              text: this.$t('FAQ.requestBook.a1m1') + ' ' + this.$t('FAQ.requestBook.a1m2'),
            },
          },
          {
            '@type': 'Question',
            name: this.stripHtml(this.$t('FAQ.requestBook.q2')),
            acceptedAnswer: {
              '@type': 'Answer',
              text: this.$t('FAQ.requestBook.a2'),
            },
          },
          {
            '@type': 'Question',
            name: this.stripHtml(this.$t('FAQ.requestBook.q3')),
            acceptedAnswer: {
              '@type': 'Answer',
              text:
                this.$t('FAQ.requestBook.a3m1') +
                ' ' +
                this.$t('FAQ.requestBook.a3m2') +
                ' ' +
                this.$t('FAQ.requestBook.a3m3'),
            },
          },
          {
            '@type': 'Question',
            name: this.stripHtml(this.$t('FAQ.requestBook.q4')),
            acceptedAnswer: {
              '@type': 'Answer',
              text: this.$t('FAQ.requestBook.a4'),
            },
          },
          {
            '@type': 'Question',
            name: this.stripHtml(this.$t('FAQ.booking.q1')),
            acceptedAnswer: {
              '@type': 'Answer',
              text:
                this.$t('FAQ.booking.a1m1') +
                ' ' +
                this.$t('FAQ.booking.a1m2') +
                ' ' +
                this.$t('FAQ.booking.a1m3'),
            },
          },
          {
            '@type': 'Question',
            name: this.stripHtml(this.$t('FAQ.booking.q2')),
            acceptedAnswer: {
              '@type': 'Answer',
              text: this.$t('FAQ.booking.a2'),
            },
          },
          {
            '@type': 'Question',
            name: this.stripHtml(this.$t('FAQ.booking.q3')),
            acceptedAnswer: {
              '@type': 'Answer',
              text: this.$t('FAQ.booking.a3'),
            },
          },
          {
            '@type': 'Question',
            name: this.stripHtml(this.$t('FAQ.digitalAgreement.q1')),
            acceptedAnswer: {
              '@type': 'Answer',
              text:
                this.$t('FAQ.digitalAgreement.a1m1') +
                ' ' +
                this.$t('FAQ.digitalAgreement.a1m2') +
                ' ' +
                this.$t('FAQ.digitalAgreement.a1m3'),
            },
          },
          {
            '@type': 'Question',
            name: this.stripHtml(this.$t('FAQ.digitalAgreement.q2')),
            acceptedAnswer: {
              '@type': 'Answer',
              text: this.$t('FAQ.digitalAgreement.a2'),
            },
          },
          {
            '@type': 'Question',
            name: this.stripHtml(this.$t('FAQ.digitalAgreement.q3')),
            acceptedAnswer: {
              '@type': 'Answer',
              text: this.$t('FAQ.digitalAgreement.a3'),
            },
          },
          {
            '@type': 'Question',
            name: this.stripHtml(this.$t('FAQ.installment.header')),
            acceptedAnswer: {
              '@type': 'Answer',
              text: this.$t('FAQ.installment.message1') + ' ' + this.$t('FAQ.installment.message2'),
            },
          },
          {
            '@type': 'Question',
            name: this.stripHtml(this.$t('FAQ.installment.q1')),
            acceptedAnswer: {
              '@type': 'Answer',
              text: this.$t('FAQ.installment.a1'),
            },
          },
          {
            '@type': 'Question',
            name: this.stripHtml(this.$t('FAQ.installment.q2')),
            acceptedAnswer: {
              '@type': 'Answer',
              text:
                this.$t('FAQ.installment.step1') +
                ' ' +
                this.$t('FAQ.installment.step2') +
                ' ' +
                this.$t('FAQ.installment.step3') +
                ' ' +
                this.$t('FAQ.installment.step4'),
            },
          },
        ],
      };
    },
  },
  data: () => ({
    bannerText: 'FAQ.helpCenter',
  }),
  mounted() {
    if (this.$route.params.type) {
      const help = this.helpList.find((help) => {
        return help.url === this.$route.params.type;
      });
      this.$store.commit('v2/help/SET_SELECTED_HELP', help);
    } else {
      this.$store.commit('v2/help/SET_SELECTED_HELP', this.helpList[0]);
    }
  },
  async fetch({ store, route }) {
    const baseUrl = store.state.global.baseUrl;
    const title = 'Rentfix.com - Platform Sewa Properti di Indonesia';
    const description =
      'Rentfix.com, situs yang membantu Anda melakukan proses sewa properti mulai dari rumah, apartemen, ruko, kantor, gudang, kios, booth, coworking space, dan ruang pertemuan di seluruh Indonesia.';
    let addOn = '';
    if (route.params && route.params.type) {
      let words = route.params.type.split('-');
      for (let i = 0; i < words.length; i++) {
        words[i] = words[i][0].toUpperCase() + words[i].substr(1);
      }
      addOn += ` | ${words.join(' ')}`;
    } else {
      addOn += ` | Pusat Bantuan`;
    }
    const head = {
      title: title + addOn,
      meta: [
        {
          hid: 'description',
          name: 'description',
          content: description + addOn,
        },
        {
          hid: 'og-description',
          name: 'og:description',
          content: description + addOn,
        },
      ],
      link: [
        {
          rel: 'canonical',
          href: baseUrl + route.fullPath,
          id: 'canonical',
        },
      ],
    };
    store.commit('head/SET_CONTENT', head);
  },
};
</script>
